import {
  UserPermission,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAuth0 } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAuth0"
import {
  LogoutIcon,
  SupportIcon,
} from "@earnnest-e2-frontend/platform-ui/src/Icons"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay/LoadingOverlay"
import {
  DashboardLayout,
  Panel,
} from "@earnnest-e2-frontend/platform-ui/src/mantine"
import AgentOnboardingCard from "@earnnest-e2-frontend/platform-views/src/mantine/AgentOnboardingCard"
import CreateEMDPartialRequestForm from "@earnnest-e2-frontend/platform-views/src/mantine/CreateEMDPartialRequestForm"
import CreateEMDRequestForm from "@earnnest-e2-frontend/platform-views/src/mantine/CreateEMDRequestForm"
import CreateGenericRequestForm from "@earnnest-e2-frontend/platform-views/src/mantine/CreateGenericRequestForm"
import InviteEscrowHolderForm from "@earnnest-e2-frontend/platform-views/src/mantine/InviteEscrowHolderForm"
import StartEMDRequestForm from "@earnnest-e2-frontend/platform-views/src/mantine/StartEMDRequestForm"
import VerifyIdentityForm from "@earnnest-e2-frontend/platform-views/src/mantine/VerifyIdentityForm"
import SupportForm from "@earnnest-e2-frontend/platform-views/src/mantine/SupportForm"
import CreatePlaidFundingSourceForm from "@earnnest-e2-frontend/platform-views/src/mantine/CreatePlaidFundingSourceForm"
import { Button, Group, Image, MediaQuery, Title } from "@mantine/core"
import {
  RiArrowLeftDownLine,
  RiArrowLeftRightFill,
  RiBankCardFill,
  RiBankFill,
  RiUser3Fill,
} from "react-icons/ri"
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom"
import CallbackPage from "./pages/CallbackPage"
import ErrorPage from "./pages/ErrorPage"
import EscrowHoldersPage from "./pages/EscrowHoldersPage"
import EscrowPanel from "./pages/EscrowPanel"
import LoginPage from "./pages/LoginPage"
import LogoutPage from "./pages/LogoutPage"
import RequestsPage from "./pages/RequestsPage"
import RolePage from "./pages/RolePage"
import TransactionPanel from "./pages/TransactionPanel"
import UserProfilePage from "./pages/UserProfilePage"
import FundingSourcesPage from "./pages/FundingSourcesPage"
import FundingSourcePanel from "./pages/FundingSourcePanel"
import AgentFeesPage from "./pages/AgentFeesPage"

export default function App() {
  const history = useHistory()
  const location = useLocation()
  const background = location.state?.background
  const { isLoading, logout } = useEarnnestAuth0()

  const userQuery = useUserQuery()
  const user = userQuery.data?.user

  if (isLoading || userQuery.loading) {
    return <LoadingOverlay />
  }

  if (user) {
    const hasAgentRole =
      user?.roles?.includes(UserPermission.RequestEscrow) ||
      user?.roles?.includes(UserPermission.Request) // we shouldn't be using this, but causing issues with listing flow
    return (
      <Switch>
        <Route path="/role">
          <RolePage />
        </Route>
        <Route path="*">
          <DashboardLayout
            logo={
              <Link to="/">
                <Image
                  src="/icon.png"
                  width={40}
                  height={40}
                  radius="md"
                  mt={-10}
                />
              </Link>
            }
            greeting={
              user.fullName.length < 20 ? user.fullName : user.firstName
            }
            mainNavItems={[
              hasAgentRole
                ? {
                    to: `/requests`,
                    icon: <RiArrowLeftRightFill width={16} height={16} />,
                    title: "Payment Requests",
                    header: (
                      <Group>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}>
                          <Title size="h2" order={1}>
                            Payment Requests
                          </Title>
                        </MediaQuery>
                        <Link
                          to={{
                            pathname: `/request/emd`,
                            state: { background: location },
                            search: location.search,
                          }}>
                          <Button
                            leftIcon={<RiArrowLeftDownLine />}
                            variant="white">
                            Request Payment
                          </Button>
                        </Link>
                      </Group>
                    ),
                  }
                : null,
              hasAgentRole
                ? {
                    to: `/escrows`,
                    icon: <RiBankFill />,
                    title: "Escrow Holders",
                    header: (
                      <Group>
                        <MediaQuery
                          smallerThan="md"
                          styles={{ display: "none" }}>
                          <Title size="h2" order={1}>
                            Escrow Holders
                          </Title>
                        </MediaQuery>
                        <Link
                          to={{
                            pathname: `/escrows/invite/`,
                            search: location.search,
                            state: { background: location },
                          }}>
                          <Button variant="white">Invite Escrow Holder</Button>
                        </Link>
                      </Group>
                    ),
                  }
                : null,
              {
                to: `/funding-sources`,
                icon: <RiBankCardFill />,
                title: "Funding Sources",
                header: (
                  <Group>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <Title size="h2" order={1}>
                        Funding Sources
                      </Title>
                    </MediaQuery>
                    <Link
                      to={{
                        pathname: `/funding-sources/add/`,
                        search: location.search,
                        state: { background: location },
                      }}>
                      <Button variant="white">Add Funding Source</Button>
                    </Link>
                  </Group>
                ),
              },
              {
                to: `/profile`,
                icon: <RiUser3Fill />,
                title: "My Profile",
                header: (
                  <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                    <Title size="h2" order={1}>
                      My Profile
                    </Title>
                  </MediaQuery>
                ),
              },
            ]}
            subNavItems={[
              {
                to: {
                  pathname: `/support`,
                  state: { background: location },
                },
                icon: <SupportIcon />,
                title: "Support",
              },
              {
                icon: <LogoutIcon />,
                title: "Logout",
                onClick: () => {
                  logout({
                    returnTo: `${
                      window.location.origin
                    }/logout?redirectTo=${encodeURIComponent(
                      window.location.pathname,
                    )}`,
                  })
                },
              },
            ]}>
            <AgentOnboardingCard />
            <Switch location={background || location}>
              {hasAgentRole ? (
                <Route path={["/requests", "/requests/*", "/request/*"]}>
                  <RequestsPage />
                </Route>
              ) : null}
              {hasAgentRole ? (
                <Route path={["/escrows", "/escrows/*"]}>
                  <EscrowHoldersPage />
                </Route>
              ) : null}
              <Route
                path={[
                  "/funding-sources",
                  "/funding-sources/*",
                  "/verify-identity",
                ]}>
                <FundingSourcesPage />
              </Route>
              <Route path="/profile">
                <UserProfilePage />
              </Route>
              <Route path="/">
                <Redirect to={hasAgentRole ? "/requests" : "/role"} />
              </Route>
              <Route path="*">
                <ErrorPage
                  errorCode="404"
                  errorMessage="This is not a valid url."
                />
              </Route>
            </Switch>
            <Route path="/funding-sources/:fundingSourceId/:tab?">
              {({ match }) => (
                <Panel
                  position="right"
                  opened={!!match}
                  onClose={() => {
                    if (location.state?.background) {
                      history.push(location.state.background)
                    } else {
                      history.push(`/funding-sources`)
                    }
                  }}>
                  {match?.params?.fundingSourceId === "add" ? (
                    <CreatePlaidFundingSourceForm
                      requireVerification={false}
                      onFundingSourceCreated={() => {
                        if (location.state?.background) {
                          history.push(location.state.background)
                        } else {
                          history.push(`/funding-sources`)
                        }
                      }}
                      getPlaidContinueUrl={() =>
                        `/funding-sources/${match.params.fundingSourceId}/`
                      }
                    />
                  ) : match ? (
                    <FundingSourcePanel />
                  ) : null}
                </Panel>
              )}
            </Route>
            <Route path="/verify-identity">
              {({ match }) => (
                <Panel
                  position="right"
                  opened={!!match}
                  onClose={() => {
                    if (location.state?.background) {
                      history.push(location.state.background)
                    } else {
                      history.push(`/funding-sources`)
                    }
                  }}>
                  {match ? (
                    <VerifyIdentityForm
                      onVerified={() => {
                        history.push(`/funding-sources/add`)
                      }}
                    />
                  ) : null}
                </Panel>
              )}
            </Route>
            {hasAgentRole ? (
              <Route path="/request/:occasion/:role?">
                {({ match }) => {
                  function handleClose() {
                    if (location.background) {
                      history.push(location.background)
                    } else {
                      history.push({
                        pathname: "/requests",
                        search: location.search,
                        state: location.state,
                      })
                    }
                  }
                  return (
                    <Panel
                      position="right"
                      opened={!!match}
                      confirmClose={true}
                      closeOnClickOutside={false}
                      onBack={
                        match?.params?.role ? () => history.goBack() : undefined
                      }
                      onClose={handleClose}>
                      {match?.params?.occasion === "emd" ? (
                        match?.params?.role === "buyeragent" ? (
                          <CreateEMDRequestForm
                            onSubmitSuccess={(transactionTemplate) => {
                              history.push({
                                pathname: `/requests/${transactionTemplate?.id}`,
                                state: location.state,
                                search: location.search,
                              })
                            }}
                          />
                        ) : match?.params?.role === "listingagent" ? (
                          <CreateEMDPartialRequestForm
                            onSubmitSuccess={handleClose}
                          />
                        ) : (
                          <StartEMDRequestForm
                            onSubmit={(role) => {
                              history.push({
                                pathname: `/request/emd/${role}`,
                                state: location.state,
                                search: location.search,
                              })
                            }}
                          />
                        )
                      ) : (
                        <CreateGenericRequestForm
                          paymentOccasionId={match?.params.occasion}
                          onSubmitSuccess={(transactionTemplate) => {
                            history.push({
                              pathname: `/requests/${transactionTemplate?.id}`,
                              state: location.state,
                              search: location.search,
                            })
                          }}
                        />
                      )}
                    </Panel>
                  )
                }}
              </Route>
            ) : null}
            {hasAgentRole ? (
              <Route path="/requests/:transactionTemplateId">
                {({ match }) => {
                  function handleClose() {
                    if (location.background) {
                      history.push(location.background)
                    } else {
                      history.push({
                        pathname: "/requests",
                        search: location.search,
                        state: location.state,
                      })
                    }
                  }
                  return (
                    <Panel
                      position="right"
                      opened={!!match}
                      onClose={handleClose}>
                      {match?.params.transactionTemplateId ? (
                        <TransactionPanel />
                      ) : null}
                    </Panel>
                  )
                }}
              </Route>
            ) : null}
            {hasAgentRole ? (
              <Route path="/escrows/:escrowAccountId">
                {({ match }) => (
                  <Panel
                    position="right"
                    opened={!!match}
                    onClose={() => {
                      if (location.background) {
                        history.push(location.background)
                      } else {
                        history.push({
                          pathname: `/escrows`,
                          search: location.search,
                          state: location.state,
                        })
                      }
                    }}>
                    {match?.params?.escrowAccountId === "invite" ? (
                      <InviteEscrowHolderForm
                        name={new URLSearchParams(location.search).get("query")}
                        onSubmitSuccess={() => {
                          if (location.background) {
                            history.push(location.background)
                          } else {
                            history.push({
                              pathname: `/escrows`,
                              search: location.search,
                              state: location.state,
                            })
                          }
                        }}
                      />
                    ) : (
                      <EscrowPanel />
                    )}
                  </Panel>
                )}
              </Route>
            ) : null}
            <Route path="/support">
              {({ match }) => (
                <Panel
                  position="right"
                  opened={!!match}
                  onClose={() => {
                    if (location.background) {
                      history.push(location.background)
                    } else {
                      history.push(`/requests`)
                    }
                  }}>
                  <SupportForm
                    viewerRole="agent"
                    extraParams={{
                      Origin: "EPDashboard",
                      Subject: "T-NA-NA-EPDashboard",
                    }}
                    onSubmitSuccess={() => {
                      if (location.background) {
                        history.push(location.background)
                      } else {
                        history.push(`/requests`)
                      }
                    }}
                  />
                </Panel>
              )}
            </Route>
          </DashboardLayout>
        </Route>
      </Switch>
    )
  } else {
    return (
      <Switch>
        <Route path="/logout">
          <LogoutPage />
        </Route>
        <Route path="/callback">
          <CallbackPage />
        </Route>
        <Route>
          <LoginPage />
        </Route>
      </Switch>
    )
  }
}
