import {
  FundingSourceFragment,
  FundingSourceStatus,
  namedOperations,
  useVerifyMicrodepositsMutation,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import { Button, Group, Input, Space, Text, Title } from "@mantine/core"
import { useForm, yupResolver } from "@mantine/form"
import { notifications } from "@mantine/notifications"
import numeral from "numeral"
import MaskedInput from "react-text-mask"
import { createNumberMask } from "text-mask-addons"
import * as yup from "yup"

export default function ManageFundingSourceForm({
  fundingSource,
  onFundingSourceVerified,
  viewerRole,
}: {
  fundingSource: FundingSourceFragment
  onFundingSourceVerified: (fundingSource: FundingSourceFragment) => void
  viewerRole?: "superuser" | "admin" | "agent"
}) {
  const mdForm = useForm({
    initialValues: {
      amount1: "",
      amount2: "",
    },
    validate: yupResolver(
      yup.object({
        amount1: yup.string().required("Required"),
        amount2: yup.string().required("Required"),
      }),
    ),
  })

  const [
    verifyMicrodeposits,
    verifyMicrodepositsState,
  ] = useVerifyMicrodepositsMutation({
    refetchQueries: [
      namedOperations.Query.EscrowAccountsList,
      namedOperations.Query.FundingSources,
      namedOperations.Query.FundingSource,
    ],
  })

  if (!fundingSource.active) {
    return <Text>Funding source is inactive.</Text>
  }

  if (
    fundingSource.status === FundingSourceStatus.Verified ||
    fundingSource.mdVerifiedAt
  ) {
    return <Text>Funding source is verified.</Text>
  }

  if (fundingSource.microdepositStatus === "processed") {
    return (
      <form
        onSubmit={mdForm.onSubmit(async (values) => {
          try {
            await verifyMicrodeposits({
              variables: {
                fundingSourceId: fundingSource.id,
                amount1: parseInt(
                  (numeral(values.amount1).value() * 100).toFixed(0),
                ),
                amount2: parseInt(
                  (numeral(values.amount2).value() * 100).toFixed(0),
                ),
                currency: "USD",
              },
            })
            notifications.show({
              title: "Success",
              message: "Microdeposits verified",
              color: "green",
            })
            onFundingSourceVerified?.(fundingSource)
          } catch (error) {
            notifications.show({
              title: "Error",
              message: error.message,
              color: "red",
            })
          }
        })}>
        <Title order={4}>Enter micro-deposit amounts</Title>
        <Text size="xs">
          Look for two small deposits from Earnnest in your{" "}
          {fundingSource.displayName}
          account.
        </Text>
        <Space h="xl" />
        <Group grow align="start">
          <Input.Wrapper
            label="First Amount"
            {...mdForm.getInputProps("amount1")}>
            <Input
              placeholder="$0.00"
              component={MaskedInput}
              mask={createNumberMask({ allowDecimal: true })}
              inputMode="numeric"
              guide={false}
              {...mdForm.getInputProps("amount1")}
            />
          </Input.Wrapper>
          <Input.Wrapper
            label="Second Amount"
            {...mdForm.getInputProps("amount2")}>
            <Input
              placeholder="$0.00"
              component={MaskedInput}
              mask={createNumberMask({ allowDecimal: true })}
              inputMode="numeric"
              guide={false}
              {...mdForm.getInputProps("amount2")}
            />
          </Input.Wrapper>
        </Group>
        <PanelFooter>
          <Button
            size="lg"
            type="submit"
            loading={verifyMicrodepositsState.loading}>
            Verify Amounts
          </Button>
        </PanelFooter>
      </form>
    )
  }

  if (fundingSource.microdepositStatus === "pending") {
    return (
      <>
        <Title order={4}>Give it a business day</Title>
        <Space h="xs" />
        <Text>
          An email notification will be sent once micro-deposits show up in this
          bank account. This usually happens within 1 business day.
        </Text>
        <Space h="sm" />
      </>
    )
  }

  if (fundingSource.mdFailedAt || fundingSource.mdVerificationFailedAt) {
    return viewerRole === "superuser" ? (
      <Text>Funding source verification failed.</Text>
    ) : (
      <Text>
        Funding source verification failed. Please contact support for
        assistance.
      </Text>
    )
  }

  if (viewerRole === "superuser") {
    return <Text>Funding source is in an unknown state.</Text>
  }

  return (
    <Text>
      Funding source is in an unknown state. Please contact support for
      assistance.
    </Text>
  )
}
