import {
  FundingSource,
  namedOperations,
  useCreateFundingSourceMutation,
  useGetOrCreateDwollaCustomerMutation,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import { useEarnnestPlaidLink } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestPlaidLink"
import {
  Button,
  LoadingOverlay,
  Space,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core"
import { useLocalStorage } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import { useEffect } from "react"
import { RiBankFill, RiPassValidFill } from "react-icons/ri"

export default function AddPlaidSourceForm({
  requireVerification,
  onVerifyIdentity,
  onFundingSourceCreated,
  getPlaidContinueUrl,
}: {
  requireVerification: boolean
  onVerifyIdentity?: () => void
  onFundingSourceCreated: (fundingSource: FundingSource) => void
  getPlaidContinueUrl: () => string
}) {
  const { track } = useEarnnestAnalytics()

  const userQuery = useUserQuery({
    notifyOnNetworkStatusChange: true,
  })
  const user = userQuery.data?.user

  const [
    createFundingSource,
    { loading: plaidSubmitting },
  ] = useCreateFundingSourceMutation()
  const [
    getOrCreateDwollaCustomer,
    { loading: customerLoading },
  ] = useGetOrCreateDwollaCustomerMutation()

  const [openPlaidLink] = useEarnnestPlaidLink()

  // persist the current payment path for oauth bank verification
  // used to return here after plaid-continue

  const newPlaidContinueReturnUri = getPlaidContinueUrl()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPlaidContinueReturnUri] = useLocalStorage({
    key: "plaidContinueReturnUri",
    defaultValue: newPlaidContinueReturnUri,
  })

  useEffect(() => {
    setPlaidContinueReturnUri(newPlaidContinueReturnUri)
  }, [newPlaidContinueReturnUri, setPlaidContinueReturnUri])

  useEffect(() => {
    track("Add Funding Source Viewed")
  }, [track])

  if (plaidSubmitting) {
    return <LoadingOverlay visible />
  }

  if (
    requireVerification &&
    userQuery.data.user?.customer?.status !== "VERIFIED"
  ) {
    return (
      <Stack align="center" justify="center" h="70vh">
        <ThemeIcon size={60}>
          <RiPassValidFill size={40} />
        </ThemeIcon>
        <Title order={3} align="center">
          Verify Your Identity
        </Title>
        <Text align="center" maw={420}>
          Before we can connect to your bank account,{"\n"}we need to confirm
          your identity.
        </Text>
        <Button onClick={onVerifyIdentity} w={240}>
          Continue
        </Button>
      </Stack>
    )
  }

  return (
    <Stack align="center" justify="center" p="xl" mih="70vh">
      <ThemeIcon size={60}>
        <RiBankFill size={40} />
      </ThemeIcon>
      <Title order={3} align="center">
        Add a bank account
      </Title>
      <Space h="lg" />
      <Title order={4} align="center">
        Instant verification via Plaid
      </Title>
      <Text align="center">
        Plaid is an industry-leading service that instantly verifies your bank
        account without sharing sensitive information with Earnnest.
      </Text>
      <Button
        disabled={userQuery.loading || customerLoading}
        style={{ width: 260, alignSelf: "center" }}
        onClick={async () => {
          track("Add Funding Source Attempted", {
            method: "PLAID",
          })
          if (!user?.customer) {
            try {
              const customerResult = await getOrCreateDwollaCustomer({
                variables: {
                  firstName: user?.firstName,
                  lastName: user.lastName,
                  email: user.email,
                  phone: user.phone,
                },
              })
              console.log({ customerResult })
            } catch (error) {
              notifications.show({
                color: "red",
                message: error.message,
              })
            }
          }
          const latestUserQuery = await userQuery.refetch()
          const latestUser = latestUserQuery.data?.user
          console.log({ latestUser })
          if (
            requireVerification &&
            latestUser?.customer?.status !== "VERIFIED"
          ) {
            alert("Some additional identity verification is required")
            onVerifyIdentity?.()
            return
          }
          if (!latestUser?.customer) {
            alert("Unable to setup customer")
            return
          }
          openPlaidLink({
            onSuccess: async (token, metadata) => {
              try {
                const result = await createFundingSource({
                  variables: {
                    plaidToken: token,
                    name: metadata.account.name,
                    bank: metadata.institution.name,
                    accountId: metadata.account.id,
                    customerRef: latestUser.customer.customerRef,
                  },
                  refetchQueries: [namedOperations.Query.User],
                })
                const fundingSource = result.data?.createFundingSource
                track("Add Funding Source Succeeded", {
                  method: "PLAID",
                  status: fundingSource.status,
                })
                onFundingSourceCreated(fundingSource as FundingSource)
              } catch (error) {
                notifications.show({
                  color: "red",
                  message: error.message,
                })
                track("Add Funding Source Failed", {
                  method: "PLAID",
                  message: error.message,
                })
              }
            },
            onError: (error) => {
              notifications.show({
                color: "red",
                message: error.message,
              })
            },
          })
        }}>
        {userQuery.loading || customerLoading ? "Loading..." : "Use Plaid"}
      </Button>
    </Stack>
  )
}
