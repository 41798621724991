import UserNavControls from "@earnnest-e2-frontend/platform-views/src/mantine/UserNavControls"
import {
  AppShell,
  Box,
  Burger,
  DefaultMantineColor,
  Divider,
  Group,
  Header,
  Indicator,
  MediaQuery,
  Navbar,
  ScrollArea,
  Space,
  Stack,
  SystemProp,
  Text,
  ThemeIcon,
  Title,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core"
import moment from "moment"
import { ReactNode, useEffect, useState } from "react"
import { Link, matchPath, useLocation, useRouteMatch } from "react-router-dom"

const maintenanceStart = process.env.REACT_APP_MAINTENANCE_START
const maintenanceBannerVisible =
  maintenanceStart && moment(maintenanceStart).isValid()

interface DashboardLayoutProps {
  children: ReactNode
  mainNavItems: NavItemProps[]
  mainNavExtra?: ReactNode
  subNavItems: NavItemProps[]
  greeting?: string
  logo?: ReactNode
  bg?: SystemProp<DefaultMantineColor>
}

export function DashboardLayout({
  children,
  mainNavItems,
  mainNavExtra,
  subNavItems,
  greeting,
  logo,
  bg = "dark.7",
}: DashboardLayoutProps) {
  const location = useLocation()
  const theme = useMantineTheme()

  const [opened, setOpened] = useState(false)
  useEffect(() => {
    setOpened(false)
  }, [location.pathname])

  const header =
    [...mainNavItems, ...subNavItems].find((x) =>
      matchPath(
        location.state?.background?.pathname || location.pathname,
        typeof x.to === "object" ? x.to.pathname : x.to,
      ),
    )?.header || null

  return (
    <AppShell
      bg={bg}
      padding={0}
      layout="alt" // Navbar goes on top of Header
      navbar={
        <Navbar
          bg={bg}
          py="sm"
          px="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 240 }}
          sx={{ borderWidth: 2 }}>
          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Burger
              opened={opened}
              onClick={() => setOpened((o) => !o)}
              size="sm"
              color={theme.fn.themeColor("gray.6")}
              sx={(theme) => ({
                [theme.fn.largerThan("sm")]: {
                  display: "none",
                },
              })}
            />
          </MediaQuery>
          <Stack mt={maintenanceBannerVisible ? 24 : 0} py={13}>
            {logo}
          </Stack>
          {greeting ? (
            <>
              <Divider
                size="sm"
                color={theme.colorScheme === "light" ? "gray.2" : "dark.5"}
                mx={-16}
              />
              <Box my="md">
                <Text color="dimmed">Welcome,</Text>
                <Title size="h4" order={5} lineClamp={1}>
                  {greeting}
                </Title>
              </Box>
            </>
          ) : null}
          <Stack py="xl" sx={{ flex: 1 }}>
            {mainNavItems.map((navItem, i) => {
              if (navItem) {
                return <NavItem key={i} {...navItem} />
              }
              return null
            })}
            {mainNavExtra}
          </Stack>
          <Space h={100} />
          <Divider
            size="sm"
            color={theme.colorScheme === "light" ? "gray.2" : "dark.5"}
            mx={-16}
          />
          <Stack py="md">
            {subNavItems.map((navItem, i) => {
              if (navItem) {
                return <NavItem key={i} {...navItem} />
              }
              return null
            })}
          </Stack>
        </Navbar>
      }
      header={
        <Header
          bg={bg}
          height={maintenanceBannerVisible ? 90 : 70}
          pt={maintenanceBannerVisible ? 36 : 12}
          pb={12}
          px="xl"
          sx={{ borderWidth: 2 }}>
          <Group>
            <Burger
              opened={opened}
              onClick={() => setOpened((o) => !o)}
              size="sm"
              sx={(theme) => ({
                marginRight: theme.spacing.sm,
                [theme.fn.largerThan("sm")]: {
                  display: "none !important",
                },
              })}
            />
            {header}
            <Box ml="auto">
              <UserNavControls />
            </Box>
          </Group>
        </Header>
      }>
      <ScrollArea
        styles={(theme) => ({
          root: {
            height: "calc(100vh - 60px)",
            [theme.fn.largerThan("md")]: {
              height: "calc(100vh - 70px)",
            },
          },
          viewport: {
            padding: theme.spacing.xl,
          },
        })}>
        {children}
      </ScrollArea>
    </AppShell>
  )
}

interface NavItemProps {
  href?: string
  to?: string | { pathname: string; state?: any; search?: string }
  disabled?: boolean
  title: string
  icon: ReactNode
  indicator?: ReactNode
  onClick?: () => void
  header?: ReactNode
}

function NavItem({
  href,
  to,
  title,
  icon,
  disabled,
  indicator,
  ...rest
}: NavItemProps) {
  const theme = useMantineTheme()

  const isActive =
    useRouteMatch({
      path: typeof to === "object" ? to.pathname : to,
    }) && !disabled

  const El = disabled ? "div" : href ? "a" : to ? Link : UnstyledButton
  const passProps = disabled
    ? null
    : to
    ? { to }
    : href
    ? { href, target: "_blank" }
    : null

  const color = isActive
    ? "green"
    : theme.colorScheme === "dark"
    ? "gray"
    : "dark"

  return (
    <El {...rest} {...passProps}>
      <Group spacing="xs" sx={{ opacity: disabled ? 0.3 : 1 }}>
        <Indicator
          color="red"
          size={16}
          offset={4}
          label={indicator}
          disabled={!indicator}
          // sx={{ width: 24, height: 24 }}
        >
          <ThemeIcon
            color={color}
            variant={isActive ? "filled" : "light"}
            radius={24}
            size="md">
            {icon}
          </ThemeIcon>
        </Indicator>
        <Text weight="bold" color={color}>
          {title}
        </Text>
      </Group>
    </El>
  )
}
