import { FundingSourceFragment } from "@earnnest-e2-frontend/platform-api/src/graphql"
import {
  FundingSourceStatusBadge,
  MetaRow,
} from "@earnnest-e2-frontend/platform-ui/src/mantine"
import dayjs from "dayjs"

export default function FundingSourceDetails({
  fundingSource,
  viewerRole = "agent",
}: {
  fundingSource: FundingSourceFragment
  viewerRole: "superuser" | "admin" | "agent"
}) {
  return (
    <>
      <MetaRow label="ID">{fundingSource.id}</MetaRow>
      <MetaRow label="Name">{fundingSource.name}</MetaRow>
      <MetaRow label="Bank">{fundingSource.bank}</MetaRow>
      <MetaRow label="Status">
        <FundingSourceStatusBadge fundingSource={fundingSource} />
      </MetaRow>
      <MetaRow label="Active">{fundingSource.active ? "Yes" : "No"}</MetaRow>
      <MetaRow label="Owner">{fundingSource.createdBy?.fullName}</MetaRow>
      <MetaRow label="Created">
        {dayjs(fundingSource.insertedAt).format("MMM D, h:mm A")}
      </MetaRow>
    </>
  )
}
