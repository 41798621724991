import {
  FundingSourceStatus,
  useFundingSourceQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import FundingSourceDetails from "@earnnest-e2-frontend/platform-views/src/mantine/FundingSourceDetails"
import ManageFundingSourceForm from "@earnnest-e2-frontend/platform-views/src/mantine/ManageFundingSourceForm"
import { Badge, Center, Loader, Space, Tabs, Title } from "@mantine/core"
import { useHistory, useLocation, useParams } from "react-router-dom"
import ErrorPage from "./ErrorPage"

export default function FundingSourcePanel() {
  const params = useParams()
  const history = useHistory()
  const location = useLocation()

  const fundingSourceQuery = useFundingSourceQuery({
    skip: !params.fundingSourceId,
    variables: {
      id: params.fundingSourceId,
    },
  })
  const fundingSource = fundingSourceQuery.data?.fundingSource

  if (fundingSourceQuery.loading) {
    return (
      <Center h="70vh">
        <Loader />
      </Center>
    )
  }

  if (fundingSourceQuery.error || !fundingSource) {
    return (
      <ErrorPage
        errorMessage={fundingSourceQuery.error?.message || "Error loading"}
      />
    )
  }

  return (
    <>
      <Title size="h2" order={2}>
        {fundingSource.displayName}
      </Title>
      <Space h="xs" />
      <Tabs
        value={params.tab || "details"}
        onTabChange={(tab) =>
          history.push({
            pathname: `/funding-sources/${params.fundingSourceId}/${tab}`,
            state: { background: location?.state?.background },
            search: params.search,
          })
        }>
        <Tabs.List>
          <Tabs.Tab value="details">Details</Tabs.Tab>
          <Tabs.Tab
            value="manage"
            rightSection={
              fundingSource.status !== FundingSourceStatus.Verified &&
              !fundingSource.mdVerifiedAt ? (
                <Badge
                  color="yellow"
                  sx={{
                    width: 16,
                    height: 16,
                    borderRadius: 8,
                  }}>
                  !
                </Badge>
              ) : null
            }>
            Manage
          </Tabs.Tab>
        </Tabs.List>
        <Space h="xl" />
        <Tabs.Panel value="details">
          <FundingSourceDetails
            fundingSource={fundingSource}
            viewerRole="agent"
          />
        </Tabs.Panel>
        <Tabs.Panel value="manage">
          <ManageFundingSourceForm
            viewerRole="agent"
            fundingSource={fundingSource}
            onFundingSourceVerified={(fundingSource) => {
              history.push({
                pathname: `/funding-sources/${fundingSource.id}/details`,
                state: { background: location?.state?.background },
                search: params.search,
              })
            }}
          />
        </Tabs.Panel>
      </Tabs>
    </>
  )
}
