import {
  FundingSourceFragment,
  FundingSourcesQueryResult,
  FundingSourceStatus,
  useUpdateUserMutation,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { FundingSourceStatusBadge } from "@earnnest-e2-frontend/platform-ui/src/mantine/StatusBadges"
import {
  ActionIcon,
  Box,
  Button,
  Group,
  Loader,
  Menu,
  Table,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { notifications } from "@mantine/notifications"
import moment from "moment"
import React, { useState } from "react"
import { RiCheckboxCircleFill, RiMoreFill } from "react-icons/ri"
import { useHistory } from "react-router-dom"

export default function UsersTable({
  fundingSourcesQuery,
  getFundingSourceLink,
  viewerRole,
}: {
  fundingSourcesQuery: FundingSourcesQueryResult
  getFundingSourceLink: (user: FundingSourceFragment) => object | string
  viewerRole: "superuser" | "admin" | "agent"
}) {
  const theme = useMantineTheme()
  const history = useHistory()

  const userQuery = useUserQuery()
  const user = userQuery.data?.user

  const [updateUser, updateUserState] = useUpdateUserMutation()

  const fundingSources = fundingSourcesQuery.data?.fundingSources?.entries
  const pageInfo = fundingSourcesQuery.data?.fundingSources?.pageInfo

  const [paginating, setPaginating] = useState(false)

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Account</th>
            <th>Status</th>
            {viewerRole === "superuser" ? <th>Owner</th> : null}
            <th>Created</th>
            {viewerRole === "superuser" ? <th>Verified</th> : null}
            <th>Active</th>
            {viewerRole === "agent" ? <th>Default</th> : null}
            {viewerRole === "agent" ? <th style={{ width: 24 }} /> : null}
          </tr>
        </thead>
        <tbody>
          {fundingSources?.map((x) => (
            <tr
              key={x.id}
              style={{
                opacity: x.active ? undefined : "50%",
              }}
              onClick={(e) => {
                history.push(getFundingSourceLink(x))
              }}>
              <td>
                <Text>{x.displayName}</Text>
              </td>
              <td>
                <FundingSourceStatusBadge fundingSource={x} />
              </td>
              {viewerRole === "superuser" ? (
                <td>
                  <Text>{x.belongsTo?.name || x.createdBy?.fullName}</Text>
                </td>
              ) : null}
              <td>
                <Text>{moment(x.insertedAt).format("MMM D, h:mm A")}</Text>
              </td>
              {viewerRole === "superuser" ? (
                <td>
                  <Text>
                    {x.mdVerifiedAt
                      ? moment(x.mdVerifiedAt).format("MMM D, h:mm A")
                      : null}
                  </Text>
                </td>
              ) : null}
              <td>
                <Text>{x.active ? "Yes" : "No"}</Text>
              </td>
              {viewerRole === "agent" ? (
                <td>
                  {user.defaultFundingSourceId === x.id ? (
                    <RiCheckboxCircleFill
                      size={20}
                      color={theme.fn.themeColor("green")}
                      style={{
                        position: "relative",
                        top: 2,
                      }}
                    />
                  ) : null}
                </td>
              ) : null}
              {viewerRole === "agent" ? (
                <td onClick={(e) => e.stopPropagation()}>
                  <Menu shadow="md" width={200} position="bottom-end">
                    <Menu.Target>
                      <ActionIcon variant="light">
                        <RiMoreFill />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        disabled={
                          x.status !== FundingSourceStatus.Verified ||
                          user.defaultFundingSourceId === x.id ||
                          updateUserState.loading
                        }
                        onClick={async () => {
                          await updateUser({
                            variables: {
                              id: user.id,
                              defaultFundingSourceId: x.id,
                            },
                          })
                        }}>
                        Set default
                      </Menu.Item>
                      {/* <Menu.Item icon={<IconMessageCircle size={14} />}>
                        Messages
                      </Menu.Item>
                      <Menu.Item icon={<IconPhoto size={14} />}>
                        Gallery
                      </Menu.Item>
                      <Menu.Item
                        icon={<IconSearch size={14} />}
                        rightSection={
                          <Text size="xs" color="dimmed">
                            ⌘K
                          </Text>
                        }>
                        Search
                      </Menu.Item>

                      <Menu.Divider />

                      <Menu.Label>Danger zone</Menu.Label>
                      <Menu.Item icon={<IconArrowsLeftRight size={14} />}>
                        Transfer my data
                      </Menu.Item>
                      <Menu.Item color="red" icon={<IconTrash size={14} />}>
                        Delete my account
                      </Menu.Item> */}
                    </Menu.Dropdown>
                  </Menu>
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </Table>
      <Box py="md">
        {fundingSources?.length ? (
          <Group position="apart">
            {pageInfo?.hasMoreEntries ? (
              <Button
                variant="white"
                loading={fundingSourcesQuery.loading || paginating}
                onClick={async () => {
                  try {
                    setPaginating(true)
                    await fundingSourcesQuery.fetchMore({
                      variables: {
                        afterCursor: pageInfo?.afterCursor || "",
                      },
                      updateQuery: (prev: any, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev
                        return {
                          ...prev,
                          fundingSources: {
                            pageInfo: fetchMoreResult?.fundingSources?.pageInfo,
                            entries: [
                              ...prev.fundingSources.entries,
                              ...(fetchMoreResult?.fundingSources?.entries ||
                                []),
                            ],
                          },
                        }
                      },
                    })
                  } catch (error) {
                    notifications.show({
                      color: "red",
                      title: "Error",
                      message: error.message,
                    })
                  } finally {
                    setPaginating(false)
                  }
                }}>
                Load more
              </Button>
            ) : null}
            <Box />
            <Text color="dimmed" size="sm">
              {pageInfo?.totalCount} total
            </Text>
          </Group>
        ) : fundingSourcesQuery.loading ? (
          <Group spacing="sm">
            <Loader size="sm" />
            <Text>Loading...</Text>
          </Group>
        ) : fundingSourcesQuery.error ? (
          <Text color="red">{fundingSourcesQuery.error.message}</Text>
        ) : null}
      </Box>
    </>
  )
}
