import {
  Context,
  TransactionTemplateFragment,
  TransactionTemplatesQueryResult,
  useApprovePaymentsMutation,
  useRejectPaymentsMutation,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import AmountText from "@earnnest-e2-frontend/platform-ui/src/mantine/AmountText"
import { TransactionTemplateStatusBadge } from "@earnnest-e2-frontend/platform-ui/src/mantine/StatusBadges"
import {
  Box,
  Button,
  Checkbox,
  Group,
  Loader,
  Paper,
  Space,
  Stack,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core"
import { useForm, yupResolver } from "@mantine/form"
import { notifications } from "@mantine/notifications"
import moment from "moment"
import { ReactNode, useState } from "react"
import { useHistory } from "react-router-dom"
import * as yup from "yup"

export default function TransactionsTable({
  query,
  getItemLink,
  selectedIds,
  onChangeSelectedIds,
  viewerRole,
  emptyLabel = <Text color="dimmed">Nothing here yet.</Text>,
}: {
  query: TransactionTemplatesQueryResult
  getItemLink: (
    transactionTemplate: TransactionTemplateFragment,
  ) => object | string
  selectedIds?: string[] | undefined
  onChangeSelectedIds?: (ids: string[]) => void
  viewerRole?: "superuser" | "admin" | "agent"
  emptyLabel?: ReactNode
}) {
  const history = useHistory()

  const items = query.data?.transactionTemplates?.entries
  const pageInfo = query.data?.transactionTemplates?.pageInfo

  const [paginating, setPaginating] = useState(false)

  return (
    <>
      {items?.length ? (
        <Table miw={800}>
          <thead>
            <tr>
              {selectedIds ? (
                <th style={{ padding: "0.5rem 0.625rem" }}>
                  <Checkbox
                    checked={selectedIds.length === items?.length}
                    indeterminate={
                      selectedIds.length && selectedIds.length < items?.length
                    }
                    onChange={() => {
                      if (selectedIds.length === items?.length) {
                        onChangeSelectedIds([])
                      } else {
                        onChangeSelectedIds(items?.map((x) => x.id))
                      }
                    }}
                  />
                </th>
              ) : null}
              {viewerRole !== "agent" ? <th>EN-ID</th> : null}
              <th>Amount</th>
              {viewerRole === "agent" ? <th>Agent Fee</th> : null}
              <th>For</th>
              <th>Status</th>
              {viewerRole !== "agent" ? <th>Requestor</th> : null}
              <th>Payer</th>
              <th>Recipient</th>
              <th>Created</th>
              <th>Authorized</th>
              <th>Deposited</th>
            </tr>
          </thead>
          <tbody>
            <Tooltip.Group closeDelay={1500}>
              {items?.map((transactionTemplate) => (
                <tr
                  key={transactionTemplate.id}
                  style={{
                    opacity: transactionTemplate.active ? undefined : "50%",
                  }}
                  onClick={(e) => {
                    if (e.target instanceof HTMLInputElement) return
                    history.push(getItemLink(transactionTemplate))
                  }}>
                  {selectedIds ? (
                    <td>
                      <Checkbox
                        checked={selectedIds.includes(transactionTemplate.id)}
                        onChange={() => {
                          if (selectedIds.includes(transactionTemplate.id)) {
                            onChangeSelectedIds?.(
                              selectedIds.filter(
                                (id) => id !== transactionTemplate.id,
                              ),
                            )
                          } else {
                            onChangeSelectedIds?.(
                              selectedIds.concat(transactionTemplate.id),
                            )
                          }
                        }}
                      />
                    </td>
                  ) : null}
                  {viewerRole !== "agent" ? (
                    <td>
                      <Text size="sm">
                        {transactionTemplate.transactions?.[0]?.traceId}
                      </Text>
                    </td>
                  ) : null}
                  <td>
                    <AmountText
                      size="md"
                      amount={transactionTemplate.amount}
                      direction={transactionTemplate.paymentOccasion?.direction}
                    />
                  </td>
                  {viewerRole === "agent" ? (
                    <td>
                      {transactionTemplate.agentCoveringFee ? (
                        <AmountText
                          size="md"
                          amount={transactionTemplate.fee}
                        />
                      ) : (
                        <Text size="sm" color="dimmed">
                          None
                        </Text>
                      )}
                    </td>
                  ) : null}
                  <td>
                    <Text>
                      {transactionTemplate.paymentOccasion?.context ===
                      Context.Emd
                        ? transactionTemplate.address ||
                          transactionTemplate.formData?.find(
                            (x) => x.name === "propaddress",
                          )?.value
                        : transactionTemplate.paymentOccasion?.name}
                    </Text>
                  </td>
                  <td>
                    <TransactionTemplateStatusBadge
                      transactionTemplate={transactionTemplate}
                    />
                  </td>
                  {viewerRole !== "agent" ? (
                    <td>
                      <Stack spacing={0}>
                        <Text>{transactionTemplate.buyerAgent?.fullName}</Text>
                        <Text size="sm" color="dimmed" lineClamp={1}>
                          {transactionTemplate.buyerAgent?.email}
                        </Text>
                      </Stack>
                    </td>
                  ) : null}
                  <td>
                    {transactionTemplate.buyer ? (
                      <Stack spacing={0}>
                        <Text>{transactionTemplate.buyer.fullName}</Text>
                        <Text size="sm" color="dimmed" lineClamp={1}>
                          {transactionTemplate.buyer.email}
                        </Text>
                      </Stack>
                    ) : null}
                  </td>
                  <td>
                    <Text size="sm" lineClamp={1}>
                      {transactionTemplate.receiverEmail}
                    </Text>
                  </td>
                  <td>
                    <Text
                      size="sm"
                      align="right"
                      color="dimmed"
                      sx={{ whiteSpace: "nowrap" }}>
                      {moment(transactionTemplate.createdAt).format("MMM D")}
                    </Text>
                  </td>
                  <td>
                    <Text
                      size="sm"
                      align="right"
                      color="dimmed"
                      sx={{ whiteSpace: "nowrap" }}>
                      {transactionTemplate?.transactions?.[0]?.authorizedAt
                        ? moment(
                            transactionTemplate?.transactions?.[0]
                              ?.authorizedAt,
                          ).format("MMM D")
                        : null}
                    </Text>
                  </td>
                  <td>
                    <Text
                      size="sm"
                      align="right"
                      color="dimmed"
                      sx={{ whiteSpace: "nowrap" }}>
                      {transactionTemplate?.transactions?.[0]?.depositedAt
                        ? moment(
                            transactionTemplate?.transactions?.[0]?.depositedAt,
                          ).format("MMM D")
                        : null}
                    </Text>
                  </td>
                </tr>
              ))}
            </Tooltip.Group>
          </tbody>
        </Table>
      ) : null}
      {items?.length ? (
        <Group py="md" position="apart">
          {pageInfo?.hasMoreEntries ? (
            <Button
              variant="white"
              loading={query.loading || paginating}
              onClick={async () => {
                try {
                  setPaginating(true)
                  await query.fetchMore({
                    variables: {
                      afterCursor: pageInfo?.afterCursor || "",
                    },
                    updateQuery: (prev: any, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev
                      return {
                        ...prev,
                        transactionTemplates: {
                          pageInfo:
                            fetchMoreResult?.transactionTemplates?.pageInfo,
                          entries: [
                            ...prev.transactionTemplates?.entries,
                            ...(fetchMoreResult?.transactionTemplates
                              ?.entries || []),
                          ],
                        },
                      }
                    },
                  })
                } catch (error) {
                  notifications.show({
                    color: "red",
                    title: "Error",
                    message: error.message,
                  })
                } finally {
                  setPaginating(false)
                }
              }}>
              Load more
            </Button>
          ) : null}
          <Box />
          <Text color="dimmed" size="sm">
            {pageInfo?.totalCount} total
          </Text>
        </Group>
      ) : query.loading ? (
        <Group spacing="sm">
          <Loader size="sm" />
          <Text>Loading...</Text>
        </Group>
      ) : query.error ? (
        <Text color="red">{query.error.message}</Text>
      ) : (
        emptyLabel
      )}
      {selectedIds?.length ? (
        <TransactionsApprovalForm
          selectedIds={selectedIds}
          onSubmitted={() => onChangeSelectedIds?.([])}
        />
      ) : null}
    </>
  )
}

function TransactionsApprovalForm({
  selectedIds,
  onSubmitted,
}: {
  selectedIds: string[]
  onSubmitted: () => void
}) {
  const [approvePayments, approvePaymentsResult] = useApprovePaymentsMutation({
    refetchQueries: ["TransactionTemplates"],
  })

  const [rejectPayments, rejectPaymentsResult] = useRejectPaymentsMutation({
    refetchQueries: ["TransactionTemplates"],
  })

  const rejectForm = useForm({
    initialValues: {
      rejectionReason: "",
    },
    validate: yupResolver(
      yup.object({
        rejectionReason: yup.string().required("Required"),
      }),
    ),
  })

  const [rejectFormVisible, setRejectFormVisible] = useState(false)

  return (
    <Paper
      shadow="md"
      radius={0}
      sx={(theme) => ({
        zIndex: 100,
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        borderTopWidth: 2,
        borderTopStyle: "solid",
        borderTopColor: theme.fn.themeColor(
          theme.colorScheme === "light" ? "gray.2" : "dark.5",
        ),
        background: theme.colorScheme === "light" ? "white" : "dark.7",
      })}>
      <Group px="xl" spacing="xs" sx={{ height: 80 }}>
        {rejectFormVisible ? (
          <form
            style={{ width: "100%" }}
            onSubmit={rejectForm.onSubmit(async (values) => {
              try {
                await rejectPayments({
                  variables: {
                    transactionTemplateIds: selectedIds,
                    reason: values.rejectionReason,
                  },
                })
                onSubmitted()
                notifications.show({
                  color: "green",
                  title: "Success",
                  message: "Transactions rejected",
                })
              } catch (error) {
                notifications.show({
                  color: "red",
                  title: "Error",
                  message: error.message,
                })
              }
            })}>
            <Group spacing="xs" noWrap>
              <TextInput
                {...rejectForm.getInputProps("rejectionReason")}
                placeholder="Enter a reason for rejection"
                sx={{ width: "100%" }}
              />
              <Button
                color="gray"
                onClick={() => setRejectFormVisible(false)}
                disabled={rejectPaymentsResult.loading}>
                Cancel
              </Button>
              <Button
                type="submit"
                color="red"
                loading={rejectPaymentsResult.loading}>
                Reject
              </Button>
            </Group>
          </form>
        ) : (
          <>
            <Text color="dimmed" size="sm">
              {selectedIds.length} selected
            </Text>
            <Space style={{ flex: 1 }} />
            <Button
              color="green"
              disabled={approvePaymentsResult.loading}
              onClick={async () => {
                try {
                  await approvePayments({
                    variables: {
                      transactionTemplateIds: selectedIds,
                    },
                  })
                  notifications.show({
                    color: "green",
                    title: "Success",
                    message: "Transactions approved",
                  })
                  onSubmitted()
                } catch (error) {
                  notifications.show({
                    color: "red",
                    title: "Error",
                    message: error.message,
                  })
                }
              }}>
              {approvePaymentsResult.loading ? "Approving..." : "Approve"}
            </Button>
            <Button
              color="red"
              disabled={approvePaymentsResult.loading}
              onClick={() => setRejectFormVisible(true)}>
              Reject
            </Button>
          </>
        )}
      </Group>
    </Paper>
  )
}
