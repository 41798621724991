import {
  FundingSourcesQueryResult,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import FundingSourcesTable from "@earnnest-e2-frontend/platform-views/src/mantine/FundingSourcesTable"
import { Button, Group, Loader, Stack, Text, Title } from "@mantine/core"
import { RiBankCardFill } from "react-icons/ri"
import { Link, useLocation } from "react-router-dom"

export default function FundingSourcesPage() {
  const userQuery = useUserQuery()
  const user = userQuery.data?.user
  const fundingSources = user?.fundingSources || []

  const location = useLocation()

  if (userQuery.loading) {
    return (
      <Group spacing="sm">
        <Loader size="sm" />
        <Text>Loading...</Text>
      </Group>
    )
  }

  if (userQuery.error) {
    return <Text color="red">{userQuery.error.message}</Text>
  }

  if (!fundingSources.length) {
    return (
      <Stack align="center" justify="center" mih="70vh">
        <RiBankCardFill color="white" size={48} />
        <Title order={3}>Set up your funding source.</Title>
        <Text>
          A funding source is a bank account that can be used to cover a payer’s
          processing fee on their behalf.
        </Text>
        <Link
          to={{
            pathname: `/funding-sources/add`,
            state: { background: location },
          }}>
          <Button variant="white">Add Funding Source</Button>
        </Link>
      </Stack>
    )
  }

  return (
    <FundingSourcesTable
      fundingSourcesQuery={
        ({
          data: {
            fundingSources: {
              entries: fundingSources,
              pageInfo: {
                hasNextPage: false,
                totalCount: fundingSources.length,
              },
            },
          },
        } as unknown) as FundingSourcesQueryResult
      }
      getFundingSourceLink={(fundingSource) => ({
        pathname: `/funding-sources/${fundingSource.id}`,
        search: location.search,
        state: location.state,
      })}
      viewerRole="agent"
    />
  )
}
