import segmentPlugin from "@analytics/segment"
import Analytics, { AnalyticsInstance } from "analytics"
import React, { createContext, useContext, useEffect, useMemo } from "react"
import { datadogLogs } from "@datadog/browser-logs"

// declare global {
//   interface Window {
//     DD_LOGS: any
//   }
// }

interface EarnnestAnalyticsContextInterface {
  page: AnalyticsInstance["page"]
  track: AnalyticsInstance["track"]
  identify: AnalyticsInstance["identify"]
}

const EarnnestAnalyticsContext = createContext<
  EarnnestAnalyticsContextInterface
>({
  page: async (...args) => console.log(...args),
  track: async (...args) => console.log(...args),
  identify: async (...args) => console.log(...args),
})

export function useEarnnestAnalytics() {
  return useContext(EarnnestAnalyticsContext)
}

export function EarnnestAnalyticsProvider({ children }: { children: any }) {
  useEffect(() => {
    if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
      datadogLogs.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: "datadoghq.com",
        env: process.env.REACT_APP_DATADOG_ENV,
        forwardErrorsToLogs: true,
        sampleRate: 100,
      })
    }
  }, [])
  const analytics = useMemo(() => {
    let plugins
    if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
      plugins = [
        segmentPlugin({ writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY }),
      ]
    }
    // if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
    //   plugins = [
    //     ...plugins,
    //     datadogAnalyticsPlugin({
    //       clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    //       env: process.env.REACT_APP_DATADOG_ENV,
    //       enabled: true,
    //     }),
    //   ]
    // }
    return Analytics({ app: "E2 Payment Portal", plugins })
  }, [])

  const page = analytics.page

  const identify = analytics.identify

  const track = analytics.track

  return (
    <EarnnestAnalyticsContext.Provider value={{ page, track, identify }}>
      {children}
    </EarnnestAnalyticsContext.Provider>
  )
}

// function datadogAnalyticsPlugin({ clientToken, env, enabled }) {
//   return {
//     name: "datadog",
//     config: {
//       clientToken,
//       env,
//       site: "datadoghq.com",
//       forwardErrorsToLogs: true,
//       sampleRate: 100,
//     },
//     enabled,
//     initialize: ({ config }) => {
//       datadogLogs.init(config)
//     },
//     page: ({ payload }) => {
//       datadogLogs.logger.info("page", payload)
//     },
//     track: ({ payload }) => {
//       datadogLogs.logger.info("track", payload)
//     },
//     identify: ({ payload }) => {
//       datadogLogs.logger.info("identify", payload)
//     },
//     loaded: () => {
//       return !!window.DD_LOGS
//     },
//   }
// }
