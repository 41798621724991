import {
  Button,
  Group,
  Paper,
  SimpleGrid,
  Space,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core"
import { useLocalStorage } from "@mantine/hooks"
import { RiCloseLine } from "react-icons/ri"
import { Link } from "react-router-dom"

const data = [
  {
    title: "1. Add a Funding Source",
    body: `Add a funding source (bank account) to your dashboard. If you have multiple funding sources, you can select which one to use for each request. Earnnest never stores or sees your banking information.`,
    prompt: `Funding Sources`,
    link: "/funding-sources",
  },
  {
    title: "2. Adjust Your Settings",
    body: `Choose whether you want to cover the fee for every request or decide on a request-by-request basis. By default, you will be asked at every request.`,
    prompt: `My Profile`,
    link: "/profile",
  },
  {
    title: "3. Send Your Payment Request",
    body: `Start a new payment request, select your funding source, and send your request—your payer will see that their fee is covered!`,
    prompt: `Start Payment Request`,
    link: "/request/emd",
  },
]

export default function AgentOnboardingCard() {
  const theme = useMantineTheme()

  const [visible, setVisible] = useLocalStorage({
    key: "agent-onboarding-card-visible",
    defaultValue: true,
  })

  if (!visible) {
    return null
  }

  return (
    <Paper
      mb="xl"
      p="xl"
      radius="md"
      sx={{
        background: theme.fn.gradient({
          from: theme.fn.themeColor("dark.5"),
          to: theme.fn.themeColor("dark.4"),
        }),
      }}>
      <Group position="apart" align="flex-start">
        <Stack>
          <Title order={6} color="white" transform="uppercase">
            New Feature
          </Title>
          <Title order={3} color="white">
            Cover Your Payer’s Processing Fee!
          </Title>
        </Stack>
        <Button
          size="sm"
          variant="white"
          rightIcon={<RiCloseLine />}
          onClick={() => setVisible(false)}>
          Hide
        </Button>
      </Group>
      <Space h="md" />
      <Text color="white">
        You now have the flexibility to cover your payer's processing fee before
        sending a payment request. Here’s how to get started:
      </Text>
      <Space h="xl" />
      <SimpleGrid
        cols={3}
        spacing="lg"
        breakpoints={[
          { maxWidth: theme.breakpoints.lg, cols: 3, spacing: "md" },
          { maxWidth: theme.breakpoints.md, cols: 2, spacing: "sm" },
        ]}>
        {data.map((x) => (
          <Stack key={x.title} spacing="xs">
            <Title order={5} color="white">
              {x.title}
            </Title>
            <Text size="sm" color="white">
              {x.body}
            </Text>
            <Link
              to={x.link}
              style={{ textDecoration: "none", marginTop: "auto" }}>
              <Text color="white" size="xs" weight="bold" transform="uppercase">
                {x.prompt} →
              </Text>
            </Link>
          </Stack>
        ))}
      </SimpleGrid>
    </Paper>
  )
}
