import { TransactionDirection } from "@earnnest-e2-frontend/platform-api/src/graphql"
import { Text, TextProps } from "@mantine/core"
import numeral from "numeral"

export function formatAmount(amount: number) {
  if (!amount) {
    return "$0"
  }
  return numeral(amount / 100).format("$0,0[.]00")
}

interface AmountTextProps extends TextProps {
  amount: number
  direction?: TransactionDirection
}

export default function AmountText({
  amount,
  direction,
  ...rest
}: AmountTextProps) {
  if (direction === TransactionDirection.Outbound) {
    return (
      <Text {...rest} span inherit>
        <Text span color="red">
          (
        </Text>
        {formatAmount(amount)}
        <Text span color="red">
          )
        </Text>
      </Text>
    )
  } else {
    return (
      <Text {...rest} span inherit>
        {formatAmount(amount)}
      </Text>
    )
  }
}
