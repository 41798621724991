import {
  CustomerStatus,
  DocumentType,
  useUserQuery,
  useVerifyIdentityWithDocumentMutation,
  useVerifyIdentityWithSsnMutation,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import {
  ActionIcon,
  Alert,
  Anchor,
  Box,
  Button,
  Card,
  Group,
  Image,
  Input,
  LoadingOverlay,
  Select,
  Space,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from "@mantine/core"
import { Dropzone } from "@mantine/dropzone"
import MaskedInput from "react-text-mask"

import { DateInput } from "@mantine/dates"
import { useForm, yupResolver } from "@mantine/form"
import { openConfirmModal } from "@mantine/modals"
import { readAndCompressImage } from "browser-image-resizer"
import { State } from "country-state-city"
import heic2any from "heic2any"
import moment from "moment"
import React, { useEffect, useState } from "react"
import {
  RiCheckboxCircleFill,
  RiCloseFill,
  RiLock2Fill,
  RiPassValidFill,
  RiUploadFill,
} from "react-icons/ri"
import { useMediaQuery } from "react-responsive"
import * as Yup from "yup"

const states = State.getStatesOfCountry("US")

export default function VerifyIdentityForm({ onVerified }) {
  const userQuery = useUserQuery()
  const customerStatus = userQuery.data?.user.customer?.status

  // Check for status updates every 3 seconds
  const shouldPollForUpdates = customerStatus !== "VERIFIED"
  useEffect(() => {
    let interval
    let timeout
    if (shouldPollForUpdates) {
      clearTimeout(timeout)
      interval = setInterval(() => {
        userQuery.refetch()
      }, 5000)
    } else {
      clearInterval(interval)
      timeout = setTimeout(() => {
        onVerified()
      }, 300)
    }
    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
  }, [userQuery, shouldPollForUpdates, onVerified])

  const [identityValues, setIdentityValues] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    ssn: "",
    line1: "",
    line2: "",
    city: "",
    stateOrRegion: "",
    postalCode: "",
  })

  switch (customerStatus) {
    case CustomerStatus.Verified:
      return (
        <Stack align="center" justify="center" h="70vh">
          <ThemeIcon size={60}>
            <RiCheckboxCircleFill size={40} />
          </ThemeIcon>
          <Title order={3} align="center">
            ID confirmed.
          </Title>
        </Stack>
      )
    case CustomerStatus.Suspended:
      return (
        <Stack align="center" justify="center" h="70vh">
          <ThemeIcon size={60}>
            <RiLock2Fill size={40} />
          </ThemeIcon>
          <Title order={3} align="center">
            Account temporarily suspended
          </Title>
          <Text align="center">
            Please contact{" "}
            <Text component="a" href="mailto:support@earnnest.com">
              support@earnnest.com
            </Text>{" "}
            to update your account.
          </Text>
        </Stack>
      )
    case CustomerStatus.Document:
      return <DocumentIdentityForm />
    case CustomerStatus.Retry:
      return <SSNIdentityForm initialValues={identityValues} retry={true} />
    case CustomerStatus.Unverified:
    default:
      return (
        <SSNIdentityForm
          initialValues={identityValues}
          onSubmitSuccess={(values) =>
            setIdentityValues({ ...values, ssn: "" })
          }
        />
      )
  }
}

function SSNIdentityForm({
  retry,
  initialValues,
  onSubmitSuccess,
}: {
  retry?: boolean
  onSubmitSuccess?: Function
  initialValues: {
    firstName: string
    lastName: string
    ssn: string
    dateOfBirth: string
    line1: string
    line2: string
    city: string
    stateOrRegion: string
    postalCode: string
  }
}) {
  const { track } = useEarnnestAnalytics()
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const userQuery = useUserQuery()

  const [
    verifyIdentityWithSsn,
    { loading: submitting },
  ] = useVerifyIdentityWithSsnMutation()

  useEffect(() => {
    track("Verify Identity Viewed", {
      method: retry ? "RETRY" : "LAST4",
    })
  }, [track, retry])

  const [error, setError] = useState<string | null>(null)

  const form = useForm({
    initialValues,
    validate: yupResolver(
      Yup.object().shape({
        firstName: Yup.string().required("Legal first name is required."),
        lastName: Yup.string().required("Legal last name is required."),
        ssn: retry
          ? Yup.string()
              .required("Enter your full SSN.")
              .length(11, "Enter your full SSN.")
          : Yup.string()
              .required("Enter the last 4 digits of your SSN.")
              .length(4, "Enter the last 4 digits of your SSN."),
        dateOfBirth: Yup.string()
          .required("Date of birth is required.")
          .test("date", "Must be a valid date (MM/DD/YYYY)", (x) =>
            moment(x).isValid(),
          )
          .test(
            ">=18",
            "Must be at least 18",
            (x) => moment().diff(x, "years") >= 18,
          )
          .test(
            "<=125",
            "Enter valid birth date",
            (x) => moment().diff(x, "years") <= 125,
          ),
        line1: Yup.string().required("Street address is required."),
        line2: Yup.string().nullable(),
        city: Yup.string().required("City is required."),
        stateOrRegion: Yup.string()
          .required("State is required.")
          .test(
            "State",
            "Enter a valid state code.",
            (str) => !!states.find((x) => x.isoCode === str.toUpperCase()),
          ),
        postalCode: Yup.string()
          .required("Zip code is required.")
          .test(">=5", "Enter a valid zip code.", (x) => String(x).length >= 5),
      }),
    ),
  })

  return (
    <>
      <Stack align="center">
        <ThemeIcon size={60}>
          <RiPassValidFill size={40} />
        </ThemeIcon>
        <Title order={3} align="center">
          {retry ? "Let’s try that again" : "Confirm Identity"}
        </Title>
        <Text align="center">
          {retry
            ? "Take this chance to double check your information."
            : "In order to protect you and your payment, we need to confirm your identity."}
        </Text>
      </Stack>
      <Space h={40} />
      <form
        onSubmit={form.onSubmit(async (values) => {
          try {
            track("Verify Identity Attempted", {
              method: retry ? "RETRY" : "LAST4",
            })
            const result = await verifyIdentityWithSsn({
              variables: {
                customerId: userQuery.data?.user.customer?.id || null,
                firstName: values.firstName,
                lastName: values.lastName,
                ssn: values.ssn,
                dateOfBirth: moment(values.dateOfBirth).format("YYYY-MM-DD"),
                address: {
                  line1: values.line1,
                  line2: values.line2,
                  city: values.city,
                  stateOrRegion: String(values.stateOrRegion).toUpperCase(),
                  postalCode: values.postalCode,
                },
              },
            })
            if (
              result.data?.verifyIdentityWithSsn.customer?.status === "RETRY"
            ) {
              form.setFieldValue("ssn", "")
            }
            track("Verify Identity Succeeded", {
              method: retry ? "RETRY" : "LAST4",
            })
            onSubmitSuccess?.(values)
          } catch (error) {
            track("Verify Identity Failed", {
              method: retry ? "RETRY" : "LAST4",
              message: error.message,
            })
            // prettier-ignore
            // Intercept specific Dwolla messages to display something more helpful to user.1
            if (error.message === "A customer with the specified email already exists.") {
              setError(`This identity data has already be used to verify another account. Please contact our support team at support@earnnest.com and we’ll help you reconcile your account.`)
            } else {
              setError(error.message)
            }
          }
        })}>
        <Stack spacing="sm">
          <Title order={4}>Identity data</Title>
          <Group align="start" noWrap={!isMobile}>
            <TextInput
              label="Legal first name"
              sx={{ width: "100%" }}
              {...form.getInputProps("firstName")}
            />
            <TextInput
              label="Legal last name"
              sx={{ width: "100%" }}
              {...form.getInputProps("lastName")}
            />
          </Group>
          <Group align="start" noWrap={!isMobile}>
            <Input.Wrapper
              label={retry ? "Full SSN" : "SSN (last 4)"}
              sx={{ width: "100%" }}
              {...form.getInputProps("ssn")}>
              <Input
                placeholder={retry ? "123-45-6789" : "6789"}
                component={MaskedInput}
                guide={false}
                mask={
                  retry
                    ? // prettier-ignore
                      [/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
                    : [/\d/, /\d/, /\d/, /\d/]
                }
                {...form.getInputProps("ssn")}
              />
            </Input.Wrapper>
            <DateInput
              label="Date of birth"
              placeholder="MM/DD/YYYY"
              sx={{ width: "100%" }}
              {...form.getInputProps("dateOfBirth")}
            />
          </Group>
          <Space h={40} />
          <Title order={4}>Current residence</Title>
          <TextInput label="Street address" {...form.getInputProps("line1")} />
          <Group align="start" noWrap={!isMobile}>
            <TextInput
              label="Address 2"
              sx={{ width: "100%" }}
              {...form.getInputProps("line2")}
            />
            <TextInput
              label="City"
              sx={{ width: "100%" }}
              {...form.getInputProps("city")}
            />
          </Group>
          <Group align="start" noWrap={!isMobile}>
            <Input.Wrapper
              label="State"
              {...form.getInputProps("stateOrRegion")}>
              <Input
                placeholder="AZ"
                component={MaskedInput}
                guide={false}
                mask={[/[a-zA-Z]/, /[a-zA-Z]/]}
                {...form.getInputProps("stateOrRegion")}
              />
            </Input.Wrapper>
            <TextInput
              label="Zip Code"
              autoComplete="off"
              {...form.getInputProps("postalCode")}
            />
          </Group>
          <Space h={40} />
          {error ? <Alert color="red">{error}</Alert> : null}
          <Button
            type="submit"
            size="lg"
            style={{ alignSelf: "center", width: 320 }}>
            {submitting ? "Submitting..." : retry ? "Retry" : "Confirm"}
          </Button>
          {retry ? null : (
            <Text size="sm" color="dimmed" align="center">
              By clicking “Confirm” you understand that you are creating a
              Dwolla account and agree to Dwolla’s{" "}
              <Anchor
                size="sm"
                href="https://www.dwolla.com/legal/tos/"
                target="_blank">
                Terms of Service
              </Anchor>
              {" and "}
              <Anchor
                size="sm"
                href="https://www.dwolla.com/legal/privacy/"
                target="_blank">
                Privacy Policy
              </Anchor>
              .
            </Text>
          )}
        </Stack>
      </form>
    </>
  )
}

function encodeImageAsDataURL(blob) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = () => reject(reader.error)
    reader.readAsDataURL(blob)
  })
}

interface FileMetadata extends File {
  preview: string
  dataURL: string
}

function DocumentIdentityForm() {
  const { track } = useEarnnestAnalytics()
  const userQuery = useUserQuery()

  const [
    verifyIdentityWithDocument,
    { loading: submitting },
  ] = useVerifyIdentityWithDocumentMutation()

  const [file, setFile] = useState<FileMetadata | null>(null)

  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const [processing, setProcessing] = useState<boolean>(false)

  useEffect(() => {
    track("Verify Identity Viewed", {
      method: "DOCUMENT",
    })
  }, [track])

  useEffect(() => {
    if (file) {
      // revoke data uris to avoid memory leaks
      return () => URL.revokeObjectURL(file.preview)
    }
  }, [file])

  const form = useForm({
    initialValues: {
      documentType: "LICENSE",
    },
    validate: yupResolver(
      Yup.object({
        documentType: Yup.string().required("Required"),
      }),
    ),
  })

  return (
    <Box>
      <Stack align="center">
        <ThemeIcon size={60}>
          <RiPassValidFill size={40} />
        </ThemeIcon>
        <Title order={3} align="center">
          Upload photo ID
        </Title>
        <Text align="center">
          Please upload a clear, color picture of your passport, state-issued
          driver’s license or U.S. government-issued photo ID.
        </Text>
      </Stack>
      <Space h={40} />
      <form
        onSubmit={form.onSubmit(async (values) => {
          if (!file) {
            setError("Upload Photo ID is required.")
            return
          }

          if (!userQuery.data?.user?.customer) {
            setError("Missing Provider Account.")
            return
          }

          try {
            setError(null)

            track("Verify Identity Attempted", {
              method: "DOCUMENT",
              documentType: values.documentType, // LICENSE | PASSPORT | IDCARD
            })

            const result = await verifyIdentityWithDocument({
              variables: {
                customerId: userQuery.data.user.customer.id,
                documentType: values.documentType as DocumentType,
                documentFile: file.dataURL,
                filename: file.name,
              },
            })
            // If mutation result has customer.status = VERIFIED, this form will immediately unmount.
            // Otherwise, the document is processing or was rejected and user can upload another document.
            // Since we don't get a status from the server, we just wait a few seconds and then
            // show an error banner and let the user upload again. This is a hacky way to do this.
            setProcessing(true)
            await new Promise((r) => setTimeout(r, 3000)) // wait 3 seconds
            setProcessing(false)
            if (
              result.data?.verifyIdentityWithDocument.customer?.status ===
              "DOCUMENT"
            ) {
              throw new Error("Document could not verified. Please try again.")
            } else {
              track("Verify Identity Succeeded", {
                method: "DOCUMENT",
                documentType: values.documentType,
              })
            }
          } catch (error) {
            track("Verify Identity Failed", {
              method: "DOCUMENT",
              documentType: values.documentType,
              message: error.message,
            })
            setError(error.message)
          }
        })}
        style={{ display: "flex", flexDirection: "column" }}>
        <Stack spacing="sm">
          <Select
            type="select"
            label="Select ID Type"
            data={[
              { label: "Driver’s License", value: DocumentType.License },
              { label: "Passport", value: DocumentType.Passport },
              { label: "ID Card", value: DocumentType.Idcard },
            ]}
            {...form.getInputProps("documentType")}
          />
          {file ? (
            <Card
              pos="relative"
              p="md"
              withBorder
              sx={{
                overflow: "visible",
                borderStyle: "dashed !important",
                borderWidth: "2px !important",
              }}>
              <Image
                src={file.preview}
                key={file.name}
                fit="contain"
                height={240}
                width="100%"
              />
              <ActionIcon
                variant="filled"
                color="dark"
                onClick={() => {
                  openConfirmModal({
                    title: "Remove photo",
                    children: "Are you sure you want to remove this photo?",
                    labels: {
                      confirm: "Remove",
                      cancel: "Cancel",
                    },
                    onConfirm: () => {
                      setFile(null)
                      setError(null)
                    },
                  })
                }}
                sx={{
                  position: "absolute",
                  top: -6,
                  right: -6,
                }}>
                <RiCloseFill />
              </ActionIcon>
              {loading ? <LoadingOverlay visible /> : null}
            </Card>
          ) : (
            <Dropzone
              accept={["image/*", ".heic"]}
              maxFiles={1}
              maxSize={3 * 1024 ** 2}
              onDrop={async ([file]) => {
                try {
                  setError(null)
                  setLoading(true)
                  if (file.size / 1024 / 1024 > 10) {
                    throw new Error("File size must be less than 10MB.")
                  }
                  let filename = file.name
                  let [filenameWithoutExtension] = file.name.split(".")
                  // Convert file to jpeg if we're dealing with raw images from iOS
                  let formattedImage = file as Blob
                  if (
                    file.type === "image/heic" ||
                    file.type === "image/heif"
                  ) {
                    formattedImage = (await heic2any({
                      blob: file,
                      toType: "image/jpeg",
                      quality: 1,
                    })) as Blob
                    // Update filename since we converted to jpeg
                    filename = filenameWithoutExtension + ".jpeg"
                  }
                  // Compress image if we're dealing with file over 2 MB
                  let resizedImage = formattedImage
                  if (file.size / 1024 / 1024 > 2) {
                    resizedImage = await readAndCompressImage(formattedImage, {
                      quality: 1.0,
                      maxWidth: 1400,
                      maxHeight: 1400,
                      autoRotate: false,
                      debug: process.env.NODE_ENV !== "production",
                      mimeType: "image/jpeg",
                    })
                    // Update filename since we converted to jpeg
                    filename = filenameWithoutExtension + ".jpeg"
                  }
                  // Process image to base64 encoded (for upload) and preview
                  const dataURL = (await encodeImageAsDataURL(
                    resizedImage,
                  )) as string
                  const preview = URL.createObjectURL(resizedImage)
                  setFile(Object.assign(file, { filename, preview, dataURL }))
                } catch (error) {
                  setError(error.message)
                } finally {
                  setLoading(false)
                }
                form.validate()
              }}
              onReject={() => {
                setError("Only JPG, PNG, or HEIC file types are accepted.")
              }}>
              <Stack h={240} align="center" justify="center">
                <Dropzone.Accept>
                  <RiUploadFill size={50} color="green" />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <RiUploadFill size={50} color="red" />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <RiUploadFill size={50} />
                </Dropzone.Idle>
                <Stack spacing={0}>
                  <Title order={5} align="center">
                    Drag file here or click to select file
                  </Title>
                  <Text size="sm" color="dimmed" align="center">
                    (JPG, PNG, HEIC)
                  </Text>
                </Stack>
              </Stack>
            </Dropzone>
          )}
          <Space h={40} />
          {error ? (
            <Box pb={16}>
              <Alert title="Error" color="red">
                {error}
              </Alert>
            </Box>
          ) : null}
          <Button
            type="submit"
            size="lg"
            sx={{ alignSelf: "center", width: 320 }}
            disabled={submitting}>
            {submitting
              ? processing
                ? "Processing..."
                : "Uploading..."
              : "Submit"}
          </Button>
        </Stack>
      </form>
    </Box>
  )
}
